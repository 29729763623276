export default [
  {
    header: 'Reliable service ',
    reviewText: 'After many failed attempts getting  my partner and kids phone location and activities I found this tracker helpful giving me location  of my kids while I was on a business trip.',
    author: 'by Daniel'
  }, {
    header: 'Helpful. I recommend ',
    reviewText: 'This is a good app that gave me an exposure and with good interface as well',
    author: 'by Kara Stylianou'
  }, {
    header: 'Good app',
    reviewText: 'Best way to find phone.',
    author: 'by Moon smithy'
  }, {
    header: 'Find what’s going on.',
    reviewText: 'Very useful tool to keep tabs on any consenting person . Very accurate and quick too!',
    author: 'by ubetispy'
  }, {
    header: 'Nice one ',
    reviewText: 'Very good and excellent app',
    author: 'by Livingstone1957'
  }
]