<template>
  <div class="screen">    
    <div class="screen__description">
      <h4>
        Users Love<br/>
        Find<span>Me</span>
      </h4>
    </div>
    <div class="reviews">
      <Swiper 
        :slides-per-view="'auto'"
        :centered-slides="true"     
        :space-between="0"
        :loop="true"
        :loopedSlides="14"
        :noSwipingClass="'no-swipe'"
        :pagination="pagination"
        :initialSlide="0"
        @swiper="initSwiper"
        @resize="updateSwiper"      
        :autoplay="{
          delay: 3000,
          speed: 500,          
          disableOnInteraction: false
        }"
      >
        <SwiperSlide v-for="(review, index) in reviews" :key="index">
          <Review :review="review"/>
        </SwiperSlide>
        <div class='swiper-pagination'></div> 
      </Swiper>      
    </div>
  </div>
</template>

<script>
//Swiper
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

import Review from './Review'
import reviews from '@/resources/data/reviews'
export default {  
  data() {
    return {      
      pagination: {
        el: `.swiper-pagination`,
        type: 'bullets',
        clickable: true
      },
      swiper: null,
      reviews: reviews
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    Review
  },
  methods: {
    initSwiper(swiper) {
      this.swiper = swiper
    },
    updateSwiper() {
      this.swiper.update()
    }
  },
  created() {
    window.addEventListener('resize', this.updateSwiper)
  },
  props: {
    screenIndex: Number,
    activeSlideIndex: Number
  },
  watch: {
    activeSlideIndex(current) {
      if (this.screenIndex === current) { 
        this.updateSwiper()       
        this.swiper.autoplay.start()
      } else {
        this.swiper.autoplay.stop()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
<style lang="scss">
@import './swiper.scss';
</style>

